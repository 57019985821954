import { recipeIdSelector } from './common/selectors'
import { defaultViewAngleId } from '../viewAngles'

export function getShareUrl(shareType: string, state): string | undefined {
  const recipeId = recipeIdSelector(state)

  const recipeUrl = encodeURIComponent(state.parentHref || window.location.href)
  const apiOrigin = window.location.origin

  const title = encodeURIComponent(
    `My ${document.title.split(' - ').reverse().join(' - ')}`,
  )

  switch (shareType) {
    case 'facebook':
      // Facebook does not work with localhost.
      return `https://facebook.com/sharer/sharer.php?u=${recipeUrl}`

    case 'twitter': {
      const { hashtag, twitterHandle } = window.serverConfig ?? {}
      let shareUrl = `https://twitter.com/intent/tweet?url=${recipeUrl}&text=${title}`
      if (twitterHandle) {
        shareUrl += `&via=${twitterHandle}`
      }
      if (hashtag) {
        shareUrl += `&hashtags=${hashtag}`
      }
      return shareUrl
    }

    case 'pinterest': {
      const media = encodeURIComponent(
        `${apiOrigin}/api/recipes/${recipeId}/${defaultViewAngleId}?modelVersion=0`,
      )
      return `https://pinterest.com/pin/create/button/?url=${recipeUrl}&media=${media}&description=${title}`
    }

    case 'email':
      return `mailto:?subject=${title}&body=${recipeUrl}`

    default:
      console.error('Unhandled case for recipe sharing!')
      return
  }
}
